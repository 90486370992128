<template>
  <div class="container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="filters" v-else>
      <div class="filters__item">
        <label class="label">Brand:</label>
        <div class="select-box">
          <AutoCompleteDropdown
            :options="brands"
            v-model="selectedBrand"
            @selected-item="setSelectedBrand"
            v-if="isBrandRefresh"
            multiple
            :apiPath="brandsApi"
          /><Loader :loading="isBrandLoader" :size="size" />
        </div>
      </div>
      <div class="filters__item" @mousedown="onCheckboxChange()">
        <Checkbox :checked="isproductListed" />
        <label
          class="label"
          style="padding-left: 0; padding-right: 0; margin-left: -20px"
          >Include delisted products</label
        >
      </div>
      <div class="filters__item">
        <label class="label">Product:<span class="red">*</span></label>
        <div class="select-box custom-width">
          <AutoCompleteDropdown
            :options="products"
            v-model="selectedProduct"
            @selected-item="setSelectedProduct"
            v-if="isProductsRefresh"
          /><Loader :loading="isProductLoader" :size="size" />
        </div>
      </div>
      <!-- Product variations -->
      <div class="filters__item">
        <label class="label">Variation:<span class="red">*</span></label>
        <div class="select-box custom-width">
          <AutoCompleteDropdown
            :options="variations"
            v-model="selectedVariation"
            @selected-item="setSelectedVariation"
            v-if="isVariationsRefresh"
          /><Loader :loading="isVariationLoader" :size="size" />
        </div>
      </div>
    </div>
    <div v-if="!isLoader">
      <div v-if="role" class="action-buttons">
        <Button
          class="create-product"
          @click="openModalProduct('create')"
          :disabled="!role.isUser"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>
          Create New Product
        </Button>
        <Button
          class="product-mapping"
          @click="openModalProduct('add')"
          :disabled="!role.isUser"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>
          Add Product Mapping
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Bus } from "../../eventBus";
import loggerService from "../../services/logger";
const FormulationBrandProductMapping = () =>
  import(
    /* webpackPrefetch: true */ "../../components/formulation-mapping/FormulationProductMappingPopup"
  );
const Product = () => import(/* webpackPrefetch: true */ "../common/Product");
import authentication from "../../authentication";
import productService from "../../services/products";

export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "../common/AutoCompleteDropdown"),
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
  },
  props: {
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoader: true,
    isBrandLoader: false,
    isProductLoader: false,
    size: "5px",
    isBrandRefresh: true,
    isProductsRefresh: true,
    selectedBrand: "",
    brandId: "",
    selectedProduct: "",
    productId: "",
    brands: [],
    isproductListed: false,
    brandIds: "",
    selectedBrands: "",
    variations: [],
    selectedVariation: "",
    variationId: "",
    isVariationsRefresh: true,
    isVariationLoader: false,
    countryId: "",
  }),
  created() {
    setTimeout(() => {
      this.getProducts("Any");
    }, 1000);
  },
  computed: {
    brandsApi() {
      return `FormulationMapping/GetBrandsByUser/${authentication.getUserEmail()}`;
    },
    ...mapGetters({
      products: "GET_PRODUCTS",
      role: "GET_USER_ROLE",
      countries: "GET_COUNTRIES",
      brand: "GET_BRAND_IDS",
    }),
  },
  methods: {
    ...mapActions({
      updateProductTable: "fetchViewProducts",
    }),
    ...mapMutations({
      updateFormulationBrandProductMappingPage: "SET_FORMULATION_IN_PRODUCT",
      updateBrandIds: "SET_BRAND_IDS",
      updateProducts: "SET_PRODUCTS",
      updateIsProductType: "SET_IS_PRODUCT_TYPE",
    }),
    clearSelected() {
      this.selectedProduct = "";
      this.selectedVariation = "";
      this.variationId = "";
      this.productId = "";
    },
    onCheckboxChange() {
      this.isproductListed = this.isproductListed ? false : true;
      this.isProductLoader = true;
      this.isVariationLoader = true;
      this.clearSelected();
      this.isProductRefresh();
      this.isVariationRefresh();
      setTimeout(() => {
        this.isProductLoader = false;
        this.isVariationLoader = false;
      }, 500);
      let selectedBrands =
        this.brandIds.length === 0 ? "Any" : this.selectedBrands.join();
      this.getProducts(selectedBrands);
      //this.getVariations();
    },
    setSelectedBrand(brand) {
      if (brand.length === 0) {
        this.selectedBrand = "";
        this.clearSelected();
        this.getProducts("Any");
        this.isProductRefresh();
        this.brandIds = [];
        this.selectedBrands = [];
        this.variations = [];
        this.updateBrandIds([]);
        return;
      }
      this.isProductLoader = true;
      setTimeout(() => {
        this.isProductLoader = false;
      }, 600);
      this.clearSelected();
      this.brandIds = brand.map((item) => {
        return item.Id;
      });
      this.selectedBrands = brand.map((item) => {
        return item.Value;
      });

      this.getProducts(this.selectedBrands.join());
      this.isVariationLoader = true;
      setTimeout(() => {
        this.isVariationLoader = false;
      }, 600);
      this.isVariationRefresh();
      this.isProductRefresh();
    },
    setSelectedProduct(product) {
      if (product === true) {
        this.clearSelected();
        this.getVariations();
        this.updateBrandIds([]);
        return;
      }
      this.countryId = product.CountryId;
      this.productId = product.Id;
      this.selectedProduct = product.Value;

      const assignFormulation = {
        brandIds: this.brandIds,
        brandnames: this.selectedBrands,
        productId: product.Id,
        productName: product.Value,
        countryId: product.CountryId,
        variationId: this.variationId,
        isVariationSelected: false,
      };
      this.updateBrandIds(assignFormulation);
      this.isVariationLoader = true;
      setTimeout(() => {
        this.isVariationLoader = false;
      }, 600);
      this.getVariations();
      this.isVariationRefresh();
      this.$emit("on-filtered", true);
    },
    setSelectedVariation(variation) {
      if (variation === true) {
        this.selectedVariation = "";
        this.variationId = "";
        const assignFormulation = {
          brandIds: this.brandIds,
          productId: this.productId,
          productName: this.selectedProduct,
          variationId: "",
          countryId: this.countryId,
          isVariationSelected: true,
        };
        this.updateBrandIds(assignFormulation);
        //this.updateBrandIds([]);
        return;
      }
      this.selectedVariation = variation.Value;
      this.variationId = variation.Id;
      const assignFormulation = {
        brandIds: this.brandIds,
        productId: this.productId,
        productName: this.selectedProduct,
        variationId: variation.Id,
        countryId: this.countryId,
        isVariationSelected: true,
      };
      this.updateBrandIds(assignFormulation);
      this.$emit("on-filtered", true);
    },
    isProductRefresh() {
      this.isProductsRefresh = false;
      this.$nextTick(() => {
        this.isProductsRefresh = true;
      });
    },
    isVariationRefresh() {
      this.isVariationsRefresh = false;
      this.$nextTick(() => {
        this.isVariationsRefresh = true;
      });
    },

    //get the products data on change of brand
    async getProducts(brand) {
      const countryIds = this.countries.map((country) => country.Id);
      let payLoad = {
        countries: countryIds.join(),
        brands: brand,
        selectDelisted: this.isproductListed,
      };
      try {
        const { data } = await productService.getProducts(payLoad);

        this.isLoader = false;
        this.updateProducts(data);
        if (data.length > 1) {
          this.updateBrandIds([]);
        }
        this.isProductLoader = false;
        this.isProductRefresh();
        this.getVariations();
      } catch (error) {
        loggerService.logError(error);

        this.isLoader = false;
        this.updateProducts([]);
        this.isProductLoader = false;
        this.isProductRefresh();
      }
    },

    //get variations
    async getVariations() {
      let payLoad = {
        emailId: authentication.getUserEmail(),
        brandName:
          this.selectedBrands === "" || this.selectedBrands.length === 0
            ? null
            : this.selectedBrands.join(),
        productId: this.productId === "" ? null : this.productId,
        selectDelisted: this.isproductListed,
      };
      try {
        const { data } = await productService.getProductVariations(payLoad);

        this.isLoader = false;
        this.variations = data;
        this.isVariationLoader = false;
        this.isVariationRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.isLoader = false;
        this.variations = [];
        this.isVariationLoader = false;
        this.isVariationRefresh();
      }
    },
    // on click of create product button
    openModalProduct(type) {
      if (type == "create") {
        this.updateIsProductType(type);
        Bus.$emit("open", {
          component: Product,
          title: "Create New Product",
          size: "xl",
          closeOnClick: false,
        });
      } else if (type == "add") {
        this.updateIsProductType(type);
        this.updateFormulationBrandProductMappingPage("product");
        Bus.$emit("open", {
          component: FormulationBrandProductMapping,
          title: "Formulation Product Brand Mapping",
          size: "md",
          closeOnClick: false,
        });
      }
    },
  },
};
</script>

<style>
div.product-mapping button.primary {
  width: 160px !important;
  margin-right: 20px;
}
div.create-product button.primary {
  width: 150px !important;
}
.action-buttons {
  position: absolute;
  width: 98.5%;
  margin-top: -8.45rem;
}
.product-mapping,
.create-product {
  float: right;
}

@media (min-width: 1280px) {
  .custom-width {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .custom-width {
    width: 300px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .custom-width {
    width: 100%;
  }
}
</style>
