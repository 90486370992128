var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "filters" }, [
            _c("div", { staticClass: "filters__item" }, [
              _c("label", { staticClass: "label" }, [_vm._v("Brand:")]),
              _c(
                "div",
                { staticClass: "select-box" },
                [
                  _vm.isBrandRefresh
                    ? _c("AutoCompleteDropdown", {
                        attrs: {
                          options: _vm.brands,
                          multiple: "",
                          apiPath: _vm.brandsApi
                        },
                        on: { "selected-item": _vm.setSelectedBrand },
                        model: {
                          value: _vm.selectedBrand,
                          callback: function($$v) {
                            _vm.selectedBrand = $$v
                          },
                          expression: "selectedBrand"
                        }
                      })
                    : _vm._e(),
                  _c("Loader", {
                    attrs: { loading: _vm.isBrandLoader, size: _vm.size }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "filters__item",
                on: {
                  mousedown: function($event) {
                    return _vm.onCheckboxChange()
                  }
                }
              },
              [
                _c("Checkbox", { attrs: { checked: _vm.isproductListed } }),
                _c(
                  "label",
                  {
                    staticClass: "label",
                    staticStyle: {
                      "padding-left": "0",
                      "padding-right": "0",
                      "margin-left": "-20px"
                    }
                  },
                  [_vm._v("Include delisted products")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "filters__item" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Product:"),
                _c("span", { staticClass: "red" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "select-box custom-width" },
                [
                  _vm.isProductsRefresh
                    ? _c("AutoCompleteDropdown", {
                        attrs: { options: _vm.products },
                        on: { "selected-item": _vm.setSelectedProduct },
                        model: {
                          value: _vm.selectedProduct,
                          callback: function($$v) {
                            _vm.selectedProduct = $$v
                          },
                          expression: "selectedProduct"
                        }
                      })
                    : _vm._e(),
                  _c("Loader", {
                    attrs: { loading: _vm.isProductLoader, size: _vm.size }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "filters__item" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Variation:"),
                _c("span", { staticClass: "red" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "select-box custom-width" },
                [
                  _vm.isVariationsRefresh
                    ? _c("AutoCompleteDropdown", {
                        attrs: { options: _vm.variations },
                        on: { "selected-item": _vm.setSelectedVariation },
                        model: {
                          value: _vm.selectedVariation,
                          callback: function($$v) {
                            _vm.selectedVariation = $$v
                          },
                          expression: "selectedVariation"
                        }
                      })
                    : _vm._e(),
                  _c("Loader", {
                    attrs: { loading: _vm.isVariationLoader, size: _vm.size }
                  })
                ],
                1
              )
            ])
          ]),
      !_vm.isLoader
        ? _c("div", [
            _vm.role
              ? _c(
                  "div",
                  { staticClass: "action-buttons" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "create-product",
                        attrs: { disabled: !_vm.role.isUser },
                        on: {
                          click: function($event) {
                            return _vm.openModalProduct("create")
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 20 20"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" Create New Product ")
                      ]
                    ),
                    _c(
                      "Button",
                      {
                        staticClass: "product-mapping",
                        attrs: { disabled: !_vm.role.isUser },
                        on: {
                          click: function($event) {
                            return _vm.openModalProduct("add")
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 20 20"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" Add Product Mapping ")
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }